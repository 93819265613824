function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { Locale, useLocation } from '@custom/schema';
var locales = Object.values(Locale);
export var defaultLocale = 'en';
export function isLocale(input) {
    return locales.includes(input);
}
/**
 * Extract the current locale from the path prefix.
 */ export function useLocale() {
    var _useLocation = _sliced_to_array(useLocation(), 1), _useLocation_ = _useLocation[0], pathname = _useLocation_.pathname, searchParams = _useLocation_.searchParams;
    var prefix = pathname.split('/')[1];
    // For the preview route, we should get the language code from the "lang"
    // parameter.
    var langcode = prefix === '__preview' ? searchParams.get('lang') : prefix;
    return isLocale(langcode) ? langcode : defaultLocale;
}
/**
 * Select the most appropriate of localization from a list of options.
 */ export function useLocalized(options) {
    var locale = useLocale();
    return (options === null || options === void 0 ? void 0 : options.filter(function(option) {
        return (option === null || option === void 0 ? void 0 : option.locale) === locale;
    }).pop()) || (options === null || options === void 0 ? void 0 : options.filter(function(option) {
        return (option === null || option === void 0 ? void 0 : option.locale) === defaultLocale;
    }).pop());
}
